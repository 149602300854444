<template>
  <div class="knowledge-base-add-component">
    <div class="knowledge-base-add-component__header">
      <div
        @click="$store.commit('changeCreateMode', false)"
        class="knowledge-base-add-component__to-back"
      >
        <i class="icon-left"></i>
        <span>Назад</span>
      </div>
    </div>
    <Widget type="loader" v-if="loading" />
    <div v-show="!loading" class="knowledge-base-add-component__container">
      <Widget
        :editValue="regulationEditContent"
        type="text-editor"
        :unique="'edit-regulation-editor'"
      />
      <div class="knowledge-base-add-component__settings">
        <h3 class="knowledge-base-add-component__settings__title">Параметры</h3>
        <CategoryPicker
          keyValue="documentTypeEditRegulation"
          :editValue="
            regulationEditCategory
              ? [
                  {
                    id:
                      regulationEditCategory === 'regulation'
                        ? 'Памятка'
                        : regulationEditCategory === 'guide'
                        ? 'Гайд'
                        : 'Инструкции',
                    name: regulationEditCategory
                  }
                ]
              : null
          "
          :isAdmin="isAdmin"
        />
        <Widget
          :style="{ marginBottom: 20 + 'px' }"
          type="input-title"
          placeholder="Название"
          :unique="'regulation-edit-name'"
          maxlength="100"
          :editValue="regulationEditName"
        />
        <Widget
          :entity="'User'"
          :actionEntity="'getItems'"
          :itemName="'full_name'"
          :keyValue="'AuthorEdit'"
          type="select-new"
          :maxCount="1"
          placeholder="Автор"
          label="Автор"
          :editValue="regulationEditAuthor"
          :hiddenItems="regulationEditCoAuthors"
        />
        <Widget
          :entity="'User'"
          :actionEntity="'getItems'"
          :itemName="'full_name'"
          :keyValue="'Co-AuthorEdit'"
          type="select-new"
          :multiple="true"
          :maxCount="3"
          placeholder="Соавтор"
          label="Соавтор"
          :editValue="regulationEditCoAuthors"
          :hiddenItems="regulationEditAuthor"
        />
        <Widget
          :entity="'Tag'"
          :actionEntity="'actionGetItems'"
          :itemName="'name'"
          :keyValue="'TagEdit'"
          type="select-new"
          :multiple="true"
          :maxCount="10"
          placeholder="Теги"
          label="Теги"
          :editValue="regulationEditTags"
          :createMode="true"
        />
        <div class="knowledge-base-add-component__job-folder" v-if="isGuide">
          <div class="label">
            <span class="text">Связь с должностной папкой</span>
            <i class="icon-info"></i>
            <p class="tooltip-reg">
              У указанных ролей документ будет отображаться в должностной папке, даже если не
              прикреплен ни к какой задаче
            </p>
          </div>
          <Widget
            :key="editRegulationRoles"
            :entity="'Role'"
            :actionEntity="'getItems'"
            :itemName="'name'"
            :keyValue="'projectRoleUpdateRegulation'"
            type="select-new"
            placeholder="Выберите проектную роль(и)"
            multiple="true"
            :editValue="editRegulationRoles"
          />
        </div>
        <div class="knowledge-base-add-component__responsibilities" v-if="isInstruction">
          <div class="label">
            <span class="text">Связь с задачей</span>
            <i class="icon-info"></i>
            <p class="tooltip-reg">У указанных ролей документ будет отображаться в их задачах</p>
          </div>
          <Widget
            :key="editRegulationResponsibilities"
            :entity="'Responsibility'"
            :actionEntity="'actionGetItems'"
            :itemName="'name'"
            :keyValue="'projectRoleUpdateResponsibilities'"
            type="select-new"
            placeholder="Выберите задачу(и)"
            multiple="true"
            :isAdminRegulation="isAdmin"
            :isRegulation="true"
            :editValue="editRegulationResponsibilities"
          />
        </div>
        <div class="knowledge-base-add-component__settings__files">
          <i class="icon-paper-clip"></i>
          <div
            class="knowledge-base-add-component__settings__wrap"
            :style="
              editOldFiles.length || editNewFiles.length || fileUploadingId.length
                ? 'flexDirection: column'
                : ''
            "
          >
            <div class="knowledge-base-add-component__settings__files__header">
              <span
                class="uploader-label"
                v-if="editOldFiles.length || editNewFiles.length || fileUploadingId.length"
                >Файлы</span
              >
              <p
                v-if="editOldFiles.length || editNewFiles.length || fileUploadingId.length"
                class="uploader-clear"
                @click="
                  editOldFiles.splice(0);
                  editNewFiles.splice(0);
                  fileUploadingId.splice(0);
                "
              >
                Очистить
              </p>
            </div>

            <FileEditorRegulation
              :style="
                !editOldFiles.length && !editNewFiles.length && !fileUploadingId.length
                  ? 'marginLeft: 0'
                  : ''
              "
              :attachedFiles="regulationData.files"
              acceptMore=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .avi, .3gp, .mpeg, .mov, .mp3, .flv, .wmv"
              @returnAllFiles="returnAllFiles"
              v-bind="{
                multiple: true,
                drop: false,
                dropDirectory: false,
                fileDrop: false,
                lengthText: 7,
                showOnlyFiles: true
              }"
            />
          </div>
        </div>
        <div class="knowledge-base-add-component__settings__links">
          <i class="icon-chain"></i>
          <Widget
            type="links-edit-widget"
            :attachedLinks="regulationData.links"
            :unique="'regulation-edit-links'"
          />
        </div>
        <div class="knowledge-base-add-component__settings__buttons">
          <ModalButton
            :type="'cancel'"
            :title="'Отменить'"
            @handleButton="$root.$emit('button:click_regulation-edit-cancel')"
          />
          <ModalButton :title="'Сохранить'" @handleButton="createChunksPoolFiles" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { domain } from "@/globalVariables";
import uploadFilesMixin from "@/helpers/Files/uploadFiles.mixin";

import linksUploaderMixin from "@/components/Widgets/LinksWidget/linksUploader.mixin";
import ModalButton from "@/components/Widgets/Button/ModalButton";
import httpClient from "@/api/client/httpClient";
const Widget = () => import("@/components/Widgets/Widget");
const FileEditorRegulation = () =>
  import("@/components/Widgets/FileUploaderCalendar/FileUploaderEdit");
const Textarea = () => import("@/components/Widgets/Textarea/Textarea");
const TabsWidgetSecond = () => import("@/components/Widgets/TabsWidget/TabsWidget2.0");
const CategoryPicker = () =>
  import("@/components/KnowledgeBaseComponents/Regulation/CategoryPicker");

export default {
  name: "RegulationEditComponent",
  mixins: [uploadFilesMixin],
  props: {
    regulationData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    CategoryPicker,
    TabsWidgetSecond,
    Textarea,
    FileEditorRegulation,
    Widget,
    linksUploaderMixin,
    ModalButton
  },
  data() {
    return {
      loading: false,
      regulationEditName: "",
      regulationEditAuthor: [],
      regulationEditCoAuthors: [],
      regulationEditCategory: null,
      regulationEditTags: [],
      regulationEditLinks: [],
      regulationEditContent: "",
      regulationEditPreview: "",
      editRegulationRoles: [],
      editRegulationResponsibilities: [],
      editOldFiles: [],
      editNewFiles: []
    };
  },
  beforeDestroy() {
    this.$root.$off("button:click_regulation-edit-cancel");
    this.$root.$off("text-editor:change_edit-regulation-editor");
    this.$root.$off("text:change_regulation-edit-name");
    this.$root.$off("changeSelectValue:AuthorEdit");
    this.$root.$off("changeSelectValue:Co-AuthorEdit");
    this.$root.$off("changeSelectValue:TagEdit");
    this.$root.$off("links:change_regulation-edit-links");
    this.$root.$off("button:click_regulation-edit-create");
    this.$root.$off("changeSelectValue:documentTypeEditRegulation");
    this.$root.$off("changeSelectValue:projectRoleUpdateRegulation");
    this.$root.$off("changeSelectValue:projectRoleUpdateResponsibilities");
  },
  mounted() {
    this.regulationEditName = this.regulationData.name;
    this.regulationEditAuthor = [this.regulationData.author];
    this.regulationEditCoAuthors = this.regulationData.co_authors;
    this.regulationEditCategory = this.regulationData.type;
    this.regulationEditTags = this.regulationData.tags;
    this.regulationEditLinks = this.regulationData.links;
    this.regulationEditContent = this.regulationData.content;
    this.regulationEditPreview = this.regulationData.preview;
    this.editRegulationRoles = this.regulationData.roles;
    this.editRegulationResponsibilities = this.regulationData.responsibilities;
    this.$root.$on("text-editor:change_edit-regulation-editor", (value) => {
      this.regulationEditContent = value.value;
      this.regulationEditPreview = value.preview;
    });
    this.$root.$on("changeSelectValue:documentTypeEditRegulation", (value) => {
      this.regulationEditCategory = value.length ? value[0].name : null;
    });
    this.$root.$on("changeSelectValue:projectRoleUpdateRegulation", (value) => {
      this.editRegulationRoles = value;
    });
    this.$root.$on("changeSelectValue:projectRoleUpdateResponsibilities", (value) => {
      this.editRegulationResponsibilities = value;
    });
    this.$root.$on("text:change_regulation-edit-name", (value) => {
      this.regulationEditName = value;
    });
    this.$root.$on("changeSelectValue:AuthorEdit", (value) => {
      this.regulationEditAuthor = value;
    });
    this.$root.$on("changeSelectValue:Co-AuthorEdit", (value) => {
      this.regulationEditCoAuthors = value;
    });
    this.$root.$on("changeSelectValue:TagEdit", (value) => {
      this.regulationEditTags = value;
    });
    this.$root.$on("links:change_regulation-edit-links", (value) => {
      this.regulationEditLinks = value;
    });
    this.$root.$on("button:click_regulation-edit-cancel", () => {
      this.$store.commit("changeCreateMode", false);
    });
    this.$root.$on("button:click_regulation-edit-create", () => {
      this.createChunksPoolFiles();
    });
  },
  methods: {
    returnAllFiles(oldFiles, newFiles) {
      this.editOldFiles = oldFiles;
      this.editNewFiles = newFiles;
      this.fileUploader = this.editNewFiles;
    },
    async createChunksPoolFiles() {
      if (!this.checkValidationData()) return null;
      this.loading = true;
      if (!this.fileUploader.length) {
        this.editRegulationItem();
        return null;
      }
      this.createPull(this.fileUploader);
    },
    editRegulationItem() {
      httpClient({
        url: `/Regulation/actionUpdate/${this.$route.params.regulation_id}`,
        method: "PUT",
        data: [
          {
            name: this.regulationEditName,
            author_user_id: this.regulationEditAuthor.length
              ? this.regulationEditAuthor[0].id
              : this.getDataInfoUser.id,
            co_authors: this.regulationEditCoAuthors.map((i) => {
              return i.id;
            }),
            tags: this.regulationEditTags.map((i) => {
              return i.id;
            }),
            type: this.regulationEditCategory,
            roles: this.isGuide
              ? this.editRegulationRoles.map((item) => {
                  return item.id;
                })
              : undefined,
            responsibilities: this.isInstruction
              ? this.editRegulationResponsibilities.map((item) => {
                  return item.id;
                })
              : undefined,
            links: this.regulationEditLinks,
            content: this.regulationEditContent,
            preview: this.regulationEditPreview,
            attachment_files: this.editOldFiles
              .map((item) => {
                return item.id;
              })
              .concat(this.fileUploadingId)
          }
        ]
      })
        .then(() => {
          this.fileUploadingId = [];
          this.loading = false;
          this.$store.commit("changeCreateMode", false);
          this.$emit("updateRegulationPage");
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `Документ успешно изменен!`
          });
        })
        .catch((error) => {
          this.loading = false;
          this.errorAlert(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filesUploaded() {
      this.editRegulationItem();
    },
    changeFilesFromUploader(files) {
      this.fileUploader = files;
    },
    checkValidationData() {
      if (!this.regulationEditCategory) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Выберите категорию!`
        });
        return false;
      }
      if (this.isInstruction && !this.isAdmin && !this.editRegulationResponsibilities.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Обязательно выберите связь с задачей!`
        });
        return false;
      }
      if (this.isGuide && !this.editRegulationRoles.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Обязательно выберите связь с должностной папкой!`
        });
        return false;
      }
      if (!this.regulationEditName.trim().length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Введите название документа!`
        });
        return false;
      }
      if (this.regulationEditName.trim().length > 100) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Название документа не может превышать 100 символов!`
        });
        return false;
      }
      if (!this.regulationEditAuthor) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Выберите автора!`
        });
        return false;
      }
      if (!this.regulationEditTags.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Должен быть выбран минимум 1 Тег!`
        });
        return false;
      }
      if (this.editOldFiles.concat(this.editNewFiles).length > 10) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Максимальное кол-во файлов 10 !`
        });
        return false;
      }
      if (
        !this.regulationEditContent.replaceAll("&nbsp;", "").trim() ||
        !this.regulationEditContent.replaceAll("<p></p>", "").trim() ||
        !this.regulationEditContent.replaceAll("<p><br></p>", "").trim()
      ) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Введите текст документа!`
        });
        return false;
      } else {
        return true;
      }
    }
  },
  computed: {
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },

    isGuide() {
      return this.regulationEditCategory === "guide" ? true : false;
    },

    isInstruction() {
      return this.regulationEditCategory === "instruction" ? true : false;
    },

    isAdmin() {
      return (
        this.getDataInfoUser.roles.includes("personnel_officer") ||
        this.getDataInfoUser.roles.includes("infrastructure_admin")
      );
    }
  },
  watch: {
    editRegulationRoles() {
      if (this.editRegulationRoles.length) {
        this.$root.$emit("tabs:block_setAvailabilityRegulationUpdate", 1);
      } else {
        this.$root.$emit("tabs:block_setAvailabilityRegulationUpdate", 0);
      }
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.knowledge-base-add-component {
  &--book {
    display: flex;
    justify-content: center;
  }

  &__bg {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .label {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: $color-text-secondary;
  }

  &__header {
    margin-bottom: 17px;
  }

  &__to-back {
    display: flex;
    align-items: center;
    max-width: max-content;
    user-select: none;
    color: $color-interactive;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    transition: all 0.2s ease;
    cursor: pointer;

    .icon-left {
      margin-right: 5px;
      font-size: 20px;
    }

    &:hover {
      color: $color-button-hover;
    }

    &:active {
      color: $color-button-click;
    }
  }

  .loader-bg {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 15%;
  }

  &__container {
    display: flex;

    @media screen and (max-width: $lg) {
      flex-flow: column-reverse;
      align-items: flex-start;
    }
  }

  &__settings {
    width: 100%;
    height: 85vh;
    max-width: 480px;
    margin-left: 20px;
    padding: 29px 32px 20px 32px;
    overflow: scroll;
    border-radius: 24px;
    background-color: $color-white;

    @media screen and (max-width: $lg) {
      max-width: 100%;
      height: 100%;
      margin-left: 0;
      margin-bottom: 40px;
    }

    &__title {
      margin: 0 0 20px;
      font-weight: 500;
      color: $color-text-accent;
      font-size: 16px;
      line-height: 20px;
    }

    /deep/ {
      .select-bg {
        margin-bottom: 20px;
        width: 100%;

        .select-content-bg {
          height: 34px;
        }

        .form-group__textarea {
          height: 160px;
        }
      }
    }

    &__files {
      display: flex;
      margin-bottom: 20px;

      .icon-paper-clip {
        margin-right: 11px;
        font-size: 20px;
        color: $color-text-secondary;
      }

      .knowledge-base-add-component__settings__wrap {
        width: 100%;
      }

      &__header {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        i {
          font-size: 20px;
          color: $icon-subdued;
          margin-right: 8px;
        }

        .uploader-label {
          font-size: 14px;
          line-height: 20px;
          color: $text-subdued;
          font-weight: 400;
        }

        .uploader-clear {
          margin-left: auto;
          color: $text-default;
          padding: 4px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;

          cursor: pointer;

          &:hover {
            background: $action-secondary-hovered;
            border-radius: 4px;
          }
        }
      }
    }

    /deep/ {
      .files-background {
        margin-left: 0 !important;
      }
    }

    &__links {
      display: flex;
      margin-bottom: 20px;

      .icon-chain {
        font-size: 20px;
        color: $color-text-secondary;
        margin-right: 8px;
        margin-top: 3px;
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 50px;

      @media screen and (max-width: $sm) {
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        margin-top: 20px;
      }

      .button-widget {
        margin-right: 15px;

        @media screen and (max-width: $sm) {
          width: 100%;
          order: 2;
          margin: 0;

          /deep/ {
            button {
              width: 100%;
            }
          }
        }

        &:last-child {
          margin-right: 0;

          @media screen and (max-width: $sm) {
            order: 1;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  /deep/ {
    .mark-select-bg {
      width: 100%;
      margin-bottom: 20px;

      .select-content-bg {
        height: 34px;
      }
    }
  }

  .tabs {
    margin-bottom: 20px;
  }

  &__job-folder {
    display: flex;
    flex-direction: column;

    .label {
      display: flex;
      align-items: center;
      position: relative;

      .text {
        margin-right: 10px;
      }

      .icon-info {
        cursor: pointer;
        font-size: 12px;

        &:hover + .tooltip-reg {
          display: block;
        }
      }

      .tooltip-reg {
        display: none;
        position: absolute;
        top: -100px;
        left: 0;
        padding: 15px;
        z-index: 1000;

        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $color-white;

        background-color: $color-interactive;
        border-radius: 10px;
      }
    }
  }

  &__responsibilities {
    display: flex;
    flex-direction: column;

    .label {
      display: flex;
      align-items: center;
      position: relative;

      .text {
        margin-right: 10px;
      }

      .icon-info {
        cursor: pointer;
        font-size: 12px;

        &:hover + .tooltip-reg {
          display: block;
        }
      }

      .tooltip-reg {
        display: none;
        position: absolute;
        top: -80px;
        left: 0;
        padding: 15px;
        z-index: 1000;

        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $color-white;

        background-color: $color-interactive;
        border-radius: 10px;
      }
    }
  }

  /deep/ {
    .background-uploader .files-background .files-info,
    .background-uploader .files-background .upload-more,
    .links-widget__item,
    .links-widget__add-more {
      @media screen and (max-width: $sm) {
        width: 100%;
        margin: 5px 0 0;
      }
    }
  }
}
</style>
